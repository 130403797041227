import React from 'react';
import mixpanel from 'mixpanel-browser';
import { AuthProvider, MixpanelContext } from './src/context';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  mixpanel.init('e8abe8cd67618baff2e18acf7471f0ff');

  return (
    <AuthProvider>
      <MixpanelContext.Provider value={mixpanel}>
        {element}
      </MixpanelContext.Provider>
    </AuthProvider>
  );
};
